#Editing div.editing-body {
    padding: 0.25em;
    position: relative;
    max-height: calc(100vh - 8.25em); /* viewport - topbar - bottombar - sidebar_titlebar*/
    display: flex;
    flex-direction: column;
}

#Editing div.editing-body > div.editing-layer-selection,
#Editing div.editing-body > div.editing-feature-selection,
#Editing div.editing-body > div.ButtonBar
#Editing div.editing-body > div.editing-busy
{
    flex: 0 0 auto;
}

#Editing div.editing-edit-frame {
    margin-top: 0.5em;
    padding-top: 0.5em;
    border-top: 1px solid @item_border@;
    flex: 1 1 auto;
    overflow-y: auto;
}

#Editing div.editing-busy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(127, 127, 127, 0.25);
    cursor: wait;
    zIndex: 2;
}

#Editing select.editing-layer-select,
#Editing select.editing-feature-select {
    width: 100%;
    border: 1px solid @item_border@;
    background-color: white;
    padding: 0.25em;
}

#Editing select.editing-layer-select:disabled,
#Editing select.editing-feature-select:disabled {
    cursor: not-allowed;
}

#Editing .edit-commit {
    background-color: rgb(127,255,127);
}

#Editing .edit-discard {
    background-color: rgb(255,127,127);
}
