#MapCompare {
    position: fixed;
    background-color: #464646;
    width: 0.25em;
    height: 100%;
    margin-left: -0.125em;
    top: 0;
    cursor: ew-resize;
}

#MapCompare span.map-compare-handle {
    position: absolute;
    top: calc(50% - 3em);
    height: 3em;
    width: 3em;
    left: -1.375em;
    background-color: #464646;
    display: flex;
    align-items: center;
    border-radius: 1.5em;
}

#MapCompare span.map-compare-handle-icon {
    flex: 0 0 1.5em;
    color: white;
    text-align: center;
}
