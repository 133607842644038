table.AutoEditForm {
    width: 100%;
}

table.AutoEditForm td {
    padding: 0.25em;
    white-space: nowrap;
}

table.AutoEditForm td:first-child {
    max-width: 10em;
    text-overflow: ellipsis;
    overflow: hidden;
}

table.AutoEditForm td:nth-child(2) {
    width: 99%;
}

table.AutoEditForm select.editing-layer-select,
table.AutoEditForm select.editing-feature-select,
table.AutoEditForm span.input-frame select {
    width: 100%;
    border: 1px solid @item_border@;
    background-color: white;
    padding: 0.25em;
}

table.AutoEditForm span.react-numeric-input {
    width: 100%;
    height: 2em;
}

table.AutoEditForm table.fields-table label {
    display: flex;
    height: 2em;
    align-items: center;
}

table.AutoEditForm label > input {
    margin-right: 0.5em;
}

table.AutoEditForm textarea {
    width: 100%;
}

table.AutoEditForm span.input-frame input {
    outline: none;
    background: none;
}

table.AutoEditForm span.input-frame {
    border: 1px solid @item_border@;
    padding: 0.25em;
    background-color: white;
    display: flex;
    align-items: center;
    height: 2em;
}

table.AutoEditForm span.input-frame input,
table.AutoEditForm span.input-frame select {
    border: 0;
    flex: 1;
    min-width: 0;
    width: 0;
}
