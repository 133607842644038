div.ThemeList {
    padding: 8px;
}

div.ThemeList .theme-group-header > span {
    font-weight: bold;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #a6a6a6;
    padding: 0.25em;
    margin: 0 0.5em 0.5em 0.5em;
}

div.ThemeList .theme-group-header > span > span.icon {
    margin-right: 0.25em;
}


div.ThemeList .theme-item {
    background-color: white;
    font-weight: bold;
    font-size: small;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    /* border: 1px solid @item_border@; */
    /* margin: 0.5em; */
    text-align: center;
    cursor: pointer;
    position: relative;
}
div.ThemeList div.theme-item-title {
    display: flex;
    align-items: center;
}

div.ThemeList div.theme-item-title > span:first-child {
    flex: 1 1 auto;
    padding: 0.125em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

div.ThemeList div.theme-item-title > span.icon {
    flex: 0 0 auto;
    padding: 0.125em;
    font-size: large;
}

div.ThemeList .theme-item-active div.theme-item-title > span:first-child
{
    color: @item_active@;
}

div.ThemeList img.theme-item-thumbnail {
    width: 100%;
    /* width: calc(15em - 2px);
    height: auto; */
}

div.ThemeList div.theme-item-icons {
    position: absolute;
    bottom: 2px;
    right: 2px;
    display: flex;
    flex-direction: column;
}

div.ThemeList div.theme-item-icons > span.icon {
    flex: 0 0 auto;
    font-size: large;
    padding: 0.25em;
    background-color: rgba(255, 255, 255, 0.75);
    color: @base_fg@;
    z-index: 2;
}

div.ThemeList div.theme-item-icons > span.icon:hover {
    color: @item_active@;
}

div.ThemeList .theme-item:hover {
    /* border: 1px solid @item_active@; */
}

div.ThemeList div.theme-item-keywords {
    position: relative;
    display: inline-block;
    color: gray;
    font-size: x-small;
    font-weight: normal;
    max-width: 80%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

div.ThemeList .theme-item-active {
    border: 1px solid @item_active@;
}

div.ThemeList div.theme-item-info-menu {
    text-align: left;
    display: flex;
    align-items: center;
    position: relative;
    color: @item_active@;
    padding: 0.25em;
}

div.ThemeList div.theme-item-info-menu-active {
    background-color: @item_active@;
    color: white;
}

div.ThemeList div.theme-item-info-menu > span.icon-info {
    margin-right: 0.25em;
}

div.ThemeList div.theme-item-info-menu > span.icon-triangle-down {
    margin-left: 0.25em;
    font-size: 80%;
}

div.ThemeList div.theme-item-info-links {
    position: absolute;
    top: 100%;
    max-width: calc(15em + 2px);
    left: -1px;
    right: -1px;
    background-color: rgba(255, 255, 255, 0.85);
    border: 1px solid @item_active@;
}

div.ThemeList div.theme-item-info-links > a {
    display: block;
    text-align: left;
    padding: 0.25em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

div.ThemeList div.theme-item-filterinfo-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 90%;
    background-color: rgba(255, 255, 255, 0.85);
    color: black;
    font-weight: normal;
    text-align: left;
    padding: 0.25em;
    border-top: 1px solid @item_border@;
    z-index: 1;
    padding-right: 2em;
}

div.ThemeList div.theme-item-filterinfo-overlay > div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

div.ThemeList div.theme-item-filterinfo-overlay > div:not(:last-child) {
    padding-bottom: 0.25em;
}
