span.ColorButton {
    position: relative;
    width: 2em;
    height: 2em;
    display: inline-block;
}

span.colorbutton-icon {
    border-radius: 0.25em;
    overflow: hidden;
    width: 2em;
    height: 2em;
    display: inline-block;
    cursor: pointer;
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 12px 12px;
    background-position: 0 0, 0 6px, 6px -6px, -6px 0;
    border: 1px solid @item_border@;
}

span.colorbutton-icon-selected {
    outline-offset: 2px;
    outline: 1px solid @item_border@;
}

span.colorbutton-icon > span {
    width: 2em;
    height: 2em;
    display: inline-block;
}

div.colorbutton-picker {
    visibility: hidden;
    position: absolute;
    left: -5.75em;
    top: 2.75em;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(136, 136, 136, 1);
    padding: 0.5em;
    border-radius: 0.25em;
    display: flex;
    flex-wrap: wrap;
    width: 13.5em;
    z-index: 1;
}

div.colorbutton-picker:before {
    content: '';
    display: block;
    position: absolute;
    left: 6em;
    top: -0.7em;
    width: 0;
    height: 0;
    border-left: 0.75em solid transparent;
    border-right: 0.75em solid transparent;
    border-bottom: 0.75em solid white;
}

div.colorbutton-picker > span.colorbutton-icon {
    flex: 0 0 auto;
    margin: 0.25em;
}

div.colorbutton-picker-input {
    margin: 0.25em;
    display: inline-flex;
    align-items: center;
    width: 13em;
    height: 2em;
}

div.colorbutton-picker-input > input[type=text] {
    display: inline-block;
    border-style: solid;
    border-color: @item_border@;
    border-width: 1px 1px 1px 0;
    height: 2em;
    width: 5em;
    padding: 0.25em;
    color: gray;
}

span.colorbutton-picker-alpha {
    border-radius: 0 0.25em 0.25em 0;
    border-style: solid;
    border-color: @item_border@;
    border-width: 1px 1px 1px 0;
    display: inline-block;
    width: 5em;
    height: 2em;
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 12px 12px;
    background-position: 0 0, 0 6px, 6px -6px, -6px 0;
}

span.colorbutton-picker-alpha > span {
    padding: 0.25em 0;
    display: inline-block;
    width: calc(5em - 1px);
    height: calc(2em - 2px);
    background-image: linear-gradient(90deg, rgba(255,255,255,0.5) 0%, white 100%);
}

span.colorbutton-picker-alpha input[type=range] {
    display: inline-block;
    width: 4.5em;
    padding: 0;
    outline: 0;
}
