div.ToggleSwitch {
    display: flex;
    width: 100%;
    height: 2em;
    overflow: hidden;
    border: 1px solid rgb(127, 255, 127);
    align-items: center;
}

div.ToggleSwitch.toggle-switch-active {
    background-color: rgb(127, 255, 127);
}

div.ToggleSwitch.toggle-switch-inactive {
    background-color: rgb(255, 127, 127);
}

div.ToggleSwitch > span.toggle-switch-slider {
    flex: 0 0 50%;
    background-color: white;
    border: 1px solid rgb(127, 255, 127);
    border-radius: 2px;
    text-align: center;
    margin: 0 0.1em;
    color: lightgray;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.ToggleSwitch > span.toggle-switch-slider > .icon {
    transform: rotate(90deg);
}

div.ToggleSwitch > span.toggle-switch-yes,
div.ToggleSwitch > span.toggle-switch-no {
    color: white;
    text-align: center;
    flex: 1 1 auto;
}

div.ToggleSwitch.toggle-switch-active > span.toggle-switch-no {
    display: none;
}

div.ToggleSwitch.toggle-switch-inactive > span.toggle-switch-yes {
    display: none;
}


div.ToggleSwitch > input {
    display: none;
}
