.ol-popup {
    position: absolute;
    text-align: center;
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    -ms-transform: translate(-50%,-100%); /* IE 9 */
    -webkit-transform: translate(-50%,-100%); /* Safari */
    transform: translate(-50%,-100%);
}
a.ol-popup-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}
.ol-popup-cnt-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}
.ol-popup-cnt-wrapper, .ol-popup-tip {
  background: white;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}
.ol-popup-cnt {
  margin: 13px 19px;
  line-height: 1.4;
   white-space: nowrap;

}
.ol-popup-tip-wrapper {
  margin: 0 auto;
  width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;

}
.ol-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
