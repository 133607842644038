#ImportLayer {
    padding: 0 0.5em;
}

#ImportLayer div.importlayer-input-fields {
    display: flex;
}


#ImportLayer div.importlayer-input-fields > select {
    flex: 0 0 auto;
    height: 2em;
    border: 1px solid @item_border@;
    background-color: @item_border@;
    color: white;
    outline: none;
}

#ImportLayer div.importlayer-input-fields > div.EditableSelect,
#ImportLayer div.importlayer-input-fields > div {
    flex: 1 1 auto;
    height: 2em;
}

#ImportLayer button.importlayer-addbutton {
    width: 100%;
    margin: 0.5em 0;
}

#ImportLayer button.importlayer-addbutton > div.spinner {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.25em;
    display: inline-block;
    flex: 0 0 auto;
}

#ImportLayer div.importlayer-list {
    padding: 0.25em;
    border: 1px solid @item_border@;
    background-color: white;
    max-height: 10em;
    overflow-y: auto;
    margin-bottom: 0.5em;
}

#ImportLayer input.importlayer-list-filter {
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: @item_border@;
    width: 100%;
    padding: 0.25em;
}

#ImportLayer div.importlayer-list-entry {
    display: flex;
    align-items: center;
    border-bottom: 1px solid @item_border@;
}

#ImportLayer div.importlayer-list-entry > span {
    flex: 1 1 auto;
    font-size: small;
    padding: 0.25em;
    cursor: pointer;
    display: inline-block;
}

#ImportLayer div.importlayer-list-entry > .icon {
    flex: 0 0 auto;
    top: 0;
}

#ImportLayer div.importlayer-list-entry > span:hover {
    color: @item_active@;
}

#ImportLayer div.importlayer-list-entry > img {
    flex: 0 0 auto;
    margin-right: 0.25em;
    cursor: pointer;
}

#ImportLayer span.importlayer-list-entry-service {
    display: inline-block;
    text-align: center;
    color: white;
    background: @item_border@;
    margin-right: 0.5em;
    border-radius: 0.25em;
    width: 6ch;
}
