#Print div.print-body {
    padding: 0.25em;
}

#Print span.option-label {
    margin-right: 1em;
    width: 10em;
}

#Print .print-minimize-maximize {
    margin-left: 1em;
    border: 1px solid transparent;
    padding: 0.25em;
}

#LayerTree span.print-minimize-maximize:hover {
    border: 1px solid white;
}

#Print table.options-table {
    width: 100%;
}

#Print table.options-table td {
    padding: 0.25em;
}

#Print table.options-table td:first-child {
    max-width: 10em;
    overflow: hidden;
    text-overflow: ellipsis;
}

#Print table.options-table td:nth-child(2) {
    width: 99%;
}

#Print table.options-table select {
    width: 100%;
    border: 1px solid @item_border@;
    background-color: white;
    padding: 0.25em;
}

#Print table.options-table input {
    outline: none;
}

#Print table.options-table span.input-frame {
    border: 1px solid @item_border@;
    padding: 0.25em;
    background-color: white;
    display: flex;
    align-items: center;
}

#Print table.options-table span.input-frame input,
#Print table.options-table span.input-frame select {
    border: 0;
    flex: 1;
    min-width: 0;
    width: 0;
    margin: 0;
}

#Print table.options-table textarea {
    border: 1px solid @item_border@;
    padding: 0.25em;
    background-color: white;
    width: 100%;
    resize: vertical;
}


div.print-output-window-body {
    width: 100%;
    height: 100%;
    position: relative;
}

div.print-output-window-body > iframe {
    border: 0;
    width: 100%;
    height: 100%;
}

span.print-output-window-wait {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 0.5em;
    padding: 0.5em;
    display: flex;
    align-items: center;
}

span.print-output-window-wait > div.spinner {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.5em;
}

#Print span.print-wait {
    display: flex;
    align-items: center;
}

#Print span.print-wait div.spinner {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.5em;
    flex: 0 0 auto;
}
