span.CopyButton {
    position: relative;
}

span.copybutton-tooltip {
    position: absolute;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.75);
    border: 1px solid black;
    color: white;
    font-size: 75%;
    font-weight: normal;
    padding: 0.5em;
    border-radius: 0.5em;
    top: 110%;
    white-space: nowrap;
}

span.CopyButton:hover span.copybutton-tooltip {
    visibility: visible;
}
